import type { LocaleCode } from '@whispli/i18n/constants'
import { DEFAULT_LOCALE_CODE, SYSTEM_LOCALES } from '@whispli/i18n/constants'

/**
 * @note Attempt to resolve locale from
 *   - `navigator.languages` (system)
 *   - `?locale={locale}`
 * or fallback to application default.
 */
export const getInitialLocale = (locales: Set<LocaleCode>): LocaleCode => {
  const locale = new URLSearchParams(window.location.search).get('locale')

  return ([
    locale,
    ...SYSTEM_LOCALES,
  ] as any[]).find(e => e && (locales.has(e) || locales.has(e.toLowerCase()))) || DEFAULT_LOCALE_CODE
}
