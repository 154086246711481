import type Vue from 'vue'
import type { VueConstructor } from 'vue'
import VueI18n from 'vue-i18n'
import { LOCALE_CODES, NO_PLURAL_LOCALES } from '@whispli/i18n/constants'
import { getInitialLocale } from '@whispli/i18n/utils/get-initial-locale'

export let i18n: VueI18n | null = null

export default async (
  vue: VueConstructor,
  options: { locale?: string, i18n?: VueI18n.I18nOptions } = {},
): Promise<VueI18n> => {
  vue.use(VueI18n)
  vue.component('I18n', (vue as any).options.components.i18n)

  const locale = options.locale || getInitialLocale(LOCALE_CODES)

  // @note Disabled. Not in use. Causes cyclic-dependency.
  // if (import.meta.env.MODE === 'production' && import.meta.env.VITE_PHRASE_PROJECT_ID) {
  //   /**
  //    * @see https://acme.staging.whispli.io/v2?phrase=true&phrase[branch]=$(git rev-parse --abbrev-ref HEAD)
  //    */
  //   (await import('@whispli/phrase/client/plugin'))
  //     .initPhraseAppInContextEditor({ projectId: import.meta.env.VITE_PHRASE_PROJECT_ID as string })
  // }
  //
  // const { PhraseAppFormatter } = await import('@whispli/phrase/client/formatter')

  i18n = new VueI18n({
    // formatter: window.PHRASEAPP_CONFIG
    //   ? new PhraseAppFormatter()
    //   : undefined,
    locale,
    fallbackLocale: locale,
    formatFallbackMessages: true,
    messages: { [locale]: {} },
    // @see https://kazupon.github.io/vue-i18n/guide/messages.html#linked-locale-messages
    // modifiers: { snakeCase: (str) => str.split(' ').join('-') },
    pluralizationRules: {
      /**
       * @see https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
       * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
       * @param choicesLength {number} an overall amount of available choices
       * @returns a final choice index to select plural word by
       */
      ...NO_PLURAL_LOCALES.reduce((obj, locale) => ({
        ...obj,
        [locale.code]: (choice, _choicesLength) => choice === 0 ? 0 : 2,
      }), {}),
    },
    silentTranslationWarn: true,
    ...options.i18n,
  })

  if (import.meta.env.MODE === 'test') {
    const interpolation = vue['options'].components.i18n.options
    const renderFn = interpolation.render
    delete vue['options'].components.i18n

    const component = {
      name: 'i18n', // eslint-disable-line
      beforeCreate: interpolation.beforeCreate,
      beforeDestroy: interpolation.beforeDestroy,
      beforeMount: interpolation.beforeMount,
      components: interpolation.components,
      directives: interpolation.directives,
      filters: interpolation.filters,
      functional: interpolation.functional,
      mounted: interpolation.mounted,
      props: interpolation.props,
      render(this, h, data) {
        return renderFn.call(this, h, {
          ...data,
          parent: { $i18n: i18n },
        })
      },
    }
    vue.component('i18n', component) // eslint-disable-line
  }

  return i18n
}

let $t: Vue['$t']
let $tc: Vue['$tc']

export const mockI18n: Pick<Vue, '$t' | '$tc'> = {} as Pick<Vue, '$t' | '$tc'>

if (import.meta.env.MODE === 'test') {
  // @ts-ignore
  $t = vi.fn<Vue['$t']>((s, r = {}) => Object.entries(r).reduce((s, [ k, v ]) => s?.replace?.(`{${k}}`, v) ?? s, s))
  // @ts-ignore
  $tc = vi.fn<Vue['$tc']>((s, n) => s?.replace?.('{n}', n) ?? s)
  mockI18n.$t = $t
  mockI18n.$tc = $tc
}

export const VUE_KEY = 'i18n' as const
